import React from "react"

import Layout from "../../components/layout"
import BlogRoll from "../../components/BlogRoll"

const BlogIndexPage = () => {
  return (
    <Layout>
      <BlogRoll />
    </Layout>
  )
}

export default BlogIndexPage
