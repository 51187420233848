import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const BlogRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <div>
      {posts &&
        posts.map(({ node: post }) => (
          <article>
            <div className="mb-8 sm:w-4/6">
              <Link
                className="font-bold text-3xl hover:text-indigo-800"
                to={post.fields.slug}
              >
                {post.frontmatter.title}
              </Link>
            </div>
            <div class="flex sm:flex-row flex-col mb-8">
              <div class="flex-none text-left text-sm text-gray-800 sm:w-1/6">
                {post.frontmatter.date}
              </div>
              <div class="flex-none text-left sm:w-3/6">
                <Img
                  style={{
                    maxWidth: "600px",
                    width: "600px",
                  }}
                  className="mb-8"
                  fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                />
                <p>{post.excerpt}</p>
              </div>
            </div>
          </article>
        ))}
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              excerpt(pruneLength: 800)
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} />}
  />
)
